.light .rmdp-range {
  background-color: #5C5C5C;
  box-shadow: 0 0 3px #5C5C5C;
}

.light .rmdp-range.start, .light .rmdp-range.end {
  background-color: #262128;
}

.light .rmdp-day:not(.rmdp-day-hidden) span:hover,
.light .rmdp-arrow-container:hover,
.light .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #5C5C5C;
}

.light .rmdp-week-day {
  color: #5C5C5C;
}

.light .rmdp-arrow {
  border: solid #5C5C5C;
  border-width: 0px 2px 2px 0px;
}

.dark .rmdp-range {
  background-color: #5C5C5C;
  box-shadow: 0 0 3px #5C5C5C;
}

.dark .rmdp-range.start, .dark .rmdp-range.end {
  background-color: #BCBCBC;
}

.dark .rmdp-week-day {
  color: #c4c7ca;
}

.dark .rmdp-arrow {
  border: solid #c4c7ca;
  border-width: 0px 2px 2px 0px;
}

.dark .rmdp-day:not(.rmdp-day-hidden) span:hover,
.dark .rmdp-arrow-container:hover,
.dark .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #5C5C5C;
}

.dark .rmdp-wrapper,
.dark .rmdp-month-picker,
.dark .rmdp-year-picker,
.dark .rmdp-container .bg-dark.ep-arrow::after {
  background-color: black;
  color: #c4c7ca;
}

.dark .rmdp-day:not(.rmdp-deactive),
.dark .rmdp-header-values,
.dark .rmdp-panel-header {
  color: #c4c7ca;
}

.dark .rmdp-day.rmdp-range {
  color: white;
}

.dark .rmdp-panel-body li {
  color: #f5f5f5;
}

.dark .rmdp-day.rmdp-deactive,
.dark .rmdp-day.rmdp-disabled {
  color: #87898b;
}

.rmdp-container {
  width: 100%;
}

.rc-time-picker-input {
  text-align: initial;
  width: 90px;
}