.sweet-loading>* {
  flex: 1 1 100px;
  align-items: center;
  justify-content: center;
}

.sweet-loading {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}