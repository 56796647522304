.trails-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trails-text {
  position: relative;
  width: 100%;
  height: 110px;
  line-height: 110px;
  color: black;
  font-size: 8em;
  font-weight: 800;
  letter-spacing: -9px;
  will-change: transform, opacity;
  overflow: hidden;
}

.trails-text > div {
  overflow: hidden;
}
